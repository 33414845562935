import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './Header'
import { Image, Button, Col, Row, Breadcrumb } from 'react-bootstrap'
import Footer from './Footer'

const useStyles = makeStyles({
    headerSpacing: {
        height: '56px'
    },
    imageContainer: {
        height: '40vw',
        overflow: 'hidden'
    },
    body: {
        backgroundColor: '#535C66',
        padding: '10%',
        alignContent: 'center',
        justifyContent: 'center',
    },
    text: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontWeight: '400'
    },
    subheading: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontStyle: 'italic'
    },
    divExpand: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10%'
    },
    button: {
        margin: '0 10px'
    },
    footer: {
        backgroundColor: '#343A40',
        color: 'white',
        padding: '3% 1% 1% 1%',
    },
    footerHeading: {
        fontWeight: 'bold',
        fontSize: '10pt',
        lineHeight: '2',
        color: '#A9B4C3',
        fontStyle: 'italic'
    },
    footerSubHeading: {
        fontWeight: '400',
        fontSize: '10pt',
        lineHeight: '2',
        color: '#676E77'
    },
    footerCopyright: {
        fontWeight: '400',
        fontSize: '10pt',
        lineHeight: '2',
        paddingLeft: '5%',
        color: '#676E77'
    },
    footerContent: {
        paddingLeft: '5%'
    }
})

const Home = () => {
    const classes = useStyles()

    const Production = () => {
        return(
            <>
                <div className={classes.imageContainer}>
                    <Image className={classes.image} src="https://i.imgur.com/bDiWjzi.jpg" fluid />
                </div>
                <div className={classes.body}>
                    <h3 className={classes.subheading}>Elite level production, at a fraction of the price.</h3>
                    <h6 className={classes.text}>Mixing, mastering and pitch. All four elements in one place. Background music, music to sing on, all in one place.</h6>
                    <div className={classes.divExpand}>
                        <Button variant="dark" className={classes.button} href="/production">Learn more.</Button>
                        <Button variant="light" className={classes.button} href="/purchaseproduction">Purchase a product.</Button>
                    </div>
                </div>
            </>
        )
    }

    const Literature = () => {
        return(
            <>
                <div className={classes.imageContainer}>
                    <Image className={classes.image} src="https://i.imgur.com/5myg4fw.jpg" fluid/>
                </div>
                <div className={classes.body}>
                    <h3 className={classes.subheading}>Entertaining literature.</h3>
                    <h6 className={classes.text}>Rest, relaxation combined with gripping storytelling, worth a fortune, costs the price of 5 days worth of coffee.</h6>
                    <div className={classes.divExpand}>
                        <Button variant="dark" className={classes.button}>Learn more.</Button>
                        <Button variant="light" className={classes.button}>Purchase a product.</Button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div>
            <div className={classes.headerSpacing}></div>
            <Production/>
            <Literature/>
            <Footer/>
        </div>
    )
}

export default Home
