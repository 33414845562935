import React from 'react'
import Header from './Header'
import Footer from './Footer'
import "./mediastyles.css"
import { makeStyles } from '@material-ui/core'

import { Image, Button, Col, Row, Breadcrumb } from 'react-bootstrap'

const useStyles = makeStyles({
    headerSpacing: {
        height: '56px'
    },
    imageContainer: {
        height: '100%',
        overflow: 'hidden'
    },
    body: {
        backgroundColor: '#535C66',
        padding: '10%',
        alignContent: 'center',
        justifyContent: 'center',
    },
    content: {
        backgroundColor: '#485059',
        width: '100%',
        padding: '10%',
    },
    root: {
        backgroundColor: '#535C66',
    },
    text: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontWeight: '400'
    },
    subText: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontWeight: '400',
        fontSize: '11pt',
        paddingTop: '5%'
    },
    subheading: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontStyle: 'italic'
    },
    divExpand: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10%'
    },
    button: {
        margin: '0 10px'
    },
    footer: {
        backgroundColor: '#343A40',
        color: 'white',
        padding: '3% 1% 1% 1%',
    },
    footerHeading: {
        fontWeight: 'bold',
        fontSize: '10pt',
        lineHeight: '2',
        color: '#A9B4C3',
        fontStyle: 'italic'
    },
    footerSubHeading: {
        fontWeight: '400',
        fontSize: '10pt',
        lineHeight: '2',
        color: '#676E77'
    },
    footerCopyright: {
        fontWeight: '400',
        fontSize: '10pt',
        lineHeight: '2',
        paddingLeft: '3%',
        color: '#676E77'
    },
    footerContent: {
        paddingLeft: '5%'
    },
    image: {
        height: '2000px',
        objectFit: 'cover'
    }
})

const Production = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Row xs={1} sm={1} md={2} lg={2} xl={2} className="media" style={{
                paddingTop: '56px',
                overflow: 'hidden'
            }}>
                <Col style={{
                    padding: 0,
                    width: '40vw',
                    objectFit: 'cover'
                }}>
                    <div className={classes.imageContainer}>
                        <Image className={classes.image} src="https://i.imgur.com/bDiWjzi.jpg" fluid />
                    </div>
                </Col>
                <Col style={{
                    padding: 0,
                }}>
                    <div className={classes.body}>
                    <h3 className={classes.subheading}>A quick peek at mastery.</h3>
                        <iframe style={{
                            padding: '5% 0% 0% 0%'
                        }} src="https://open.spotify.com/embed/artist/102hiIhVoGV8C47Q30LIcs" width="100%" height="450vh" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                </Col>
            </Row>
            <Row style={{
                overflow: 'hidden'
            }}>
                <div className={classes.content}>
                    <h3 className={classes.subheading}>Elite level production, at a fraction of the price.</h3>
                    <h6 className={classes.text}>Mixing, mastering and pitch. All three elements in one place. Background music, music to sing on, all in one place.</h6>
                    <h6 className={classes.subText}>Available at a variety of prices, licensed to you and only you. Freedom to do whatever you want.</h6>
                    <div className={classes.divExpand}>
                        <Button variant="light" className={classes.button} href="/purchaseProduction">Purchase a product.</Button>
                    </div>
                </div>
            </Row>
            <Footer/>
        </div>
    )
}

export default Production
