import React from 'react'
import Header from './Header'
import "./mediastyles.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import { makeStyles } from '@material-ui/core'

import { Image, Button, Col, Row, Breadcrumb } from 'react-bootstrap'
import Footer from './Footer'


const useStyles = makeStyles({
    headerSpacing: {
        height: '56px'
    },
    imageContainer: {
        height: '50vh',
        overflow: 'hidden',
    },
    body: {
        backgroundColor: '#535C66',
        padding: '10%',
        alignContent: 'center',
        justifyContent: 'center',
    },
    content: {
        backgroundColor: '#485059',
        width: '100%',
        padding: '10%',
    },
    root: {
        backgroundColor: '#535C66',
    },
    text: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontWeight: '400'
    },
    subText: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontWeight: '400',
        fontSize: '11pt',
        paddingTop: '5%'
    },
    subheading: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontStyle: 'italic'
    },
    divExpand: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '56px'
    },
    button: {
        margin: '0 10px'
    },
    footer: {
        backgroundColor: '#343A40',
        color: 'white',
        padding: '3% 1% 1% 1%',
    },
    footerHeading: {
        fontWeight: 'bold',
        fontSize: '10pt',
        lineHeight: '2',
        color: '#A9B4C3',
        fontStyle: 'italic'
    },
    footerSubHeading: {
        fontWeight: '400',
        fontSize: '10pt',
        lineHeight: '2',
        color: '#676E77'
    },
    footerCopyright: {
        fontWeight: '400',
        fontSize: '10pt',
        lineHeight: '2',
        paddingLeft: '5%',
        color: '#676E77'
    },
    footerContent: {
        paddingLeft: '5%'
    }
})

const PurchaseProduction = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.divExpand}/>
            <div className={classes.content}>
                <h3 className={classes.subheading}>Elite level production, at a fraction of the price.</h3>
                <h6 className={classes.text}>Mixing, mastering and pitch. All four elements in one place. Background music, music to sing on, all in one place.</h6>
                <h6 className={classes.subText}>Available at a variety of prices, licensed to you. Freedom to do whatever you want.</h6>
            </div>
            <div className={classes.imageContainer}>
                    <Image className={classes.image} src="https://i.imgur.com/bDiWjzi.jpg" fluid />
            </div>
            <div className={classes.body}>
                <h3 className={classes.subheading}>Purchase a product.</h3>
                {/*<iframe src="https://player.beatstars.com/?storeId=122511" width="100%" height="800" style="max-width:1024px;"> -- none -- </iframe>*/}
                <iframe src="https://player.beatstars.com/?storeId=122511" width="100%" height="800" style={{
                    marginTop: '10%',
                    boxShadow: 'none',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    overflow: 'hidden'
                }}> -- none -- </iframe>
            </div>
            <Footer/>
        </div>
    )
}

export default PurchaseProduction
