import React from 'react'
import { makeStyles } from '@material-ui/core'

import 'bootstrap/dist/css/bootstrap.min.css'

import { Link } from 'react-router-dom'
import { Image, Button, Col, Row, Breadcrumb } from 'react-bootstrap'

const useStyles = makeStyles({
    headerSpacing: {
        height: '56px'
    },
    imageContainer: {
        height: '40vw',
        overflow: 'hidden'
    },
    body: {
        backgroundColor: '#535C66',
        padding: '10%',
        alignContent: 'center',
        justifyContent: 'center',
    },
    text: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontWeight: '400'
    },
    subheading: {
        color: 'white',
        textAlign: 'center',
        lineHeight: '1.5',
        fontStyle: 'italic'
    },
    divExpand: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10%'
    },
    button: {
        margin: '0 10px'
    },
    footer: {
        backgroundColor: '#343A40',
        color: 'white',
        padding: '3% 1% 1% 1%',
    },
    footerHeading: {
        fontWeight: 'bold',
        fontSize: '10pt',
        lineHeight: '2',
        color: '#A9B4C3',
        fontStyle: 'italic'
    },
    footerSubHeading: {
        fontWeight: '400',
        fontSize: '10pt',
        lineHeight: '2',
        color: '#676E77'
    },
    footerCopyright: {
        fontWeight: '400',
        fontSize: '10pt',
        lineHeight: '2',
        paddingLeft: '5%',
        color: '#676E77'
    },
    footerContent: {
        paddingLeft: '5%'
    }
})

const Footer = () => {
    const classes = useStyles()

    return (
        <div className={classes.footer}>
            <div className={classes.footerContent}>
                <Row>
                    <Col>
                        <Row className={classes.footerHeading}>Production</Row>
                        <Row className={classes.footerSubHeading}><Link to="/home" style={{
                            color: "#626D77"
                        }}>Summary</Link></Row>
                        <Row className={classes.footerSubHeading}><Link to="/production" style={{
                            color: "#626D77"
                        }}>Learn more</Link></Row>
                    </Col>
                    <Col>
                        <Row className={classes.footerHeading}>Literature</Row>
                        <Row className={classes.footerSubHeading}><Link to="/home" style={{
                            color: "#626D77"
                        }}>Summary</Link></Row>
                        <Row className={classes.footerSubHeading}><Link to="/literature" style={{
                            color: "#626D77"
                        }}>Learn more</Link></Row>
                    </Col>
                    <Col>
                        <Row className={classes.footerHeading}>Information</Row>
                        <Row className={classes.footerSubHeading}><Link to="/about" style={{
                            color: "#626D77"
                        }}>About</Link></Row>
                        <Row className={classes.footerSubHeading}><Link to="/contact" style={{
                            color: "#626D77"
                        }}>Contact</Link></Row>
                    </Col>
                </Row>
            </div>
            <hr></hr>
            <Row>
                <p className={classes.footerCopyright}>Copyright © 2021 Satvik Prasad. All rights reserved.</p>
            </Row>
        </div>
    )
}

export default Footer
