import Header from './components/Header'
import Home from './components/Home'
import Production from './components/Production'
import PurchaseProduction from './components/PurchaseProduction'
import Literature from './components/Literature'
import About from './components/About'
import Contact from './components/Contact'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom' 
 
function App() {
  return (
    <>
    <Header/>
    <Router>
      <Switch>
        <Route exact path="/home">
          <Home/>
        </Route>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route exact path="/production">
          <Production />
        </Route>
        <Route exact path="/literature">
          <Literature />
        </Route>
        <Route exact path="/purchaseProduction">
          <PurchaseProduction />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
      </Switch>
    </Router>
    </>
  );
}

export default App;
