import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom' 

import 'bootstrap/dist/css/bootstrap.min.css'

import { Navbar, Nav, Form, FormControl, Button, NavDropdown } from 'react-bootstrap'

const useStyles = makeStyles({
  root: {
    fontFamily: ['Roboto Mono', 'monospace'].join(',')
  }
})

const Header = () => {
  const classes = useStyles()

  return (
      <div className={classes.root}>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" style={{
          boxShadow: '0 8px 6px -6px #343A40'
        }}>
          <Navbar.Brand href="#home">Satvik Prasad</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/home">Home</Nav.Link>
              <Nav.Link href="/production">Production</Nav.Link>
              <Nav.Link href="/literature">Literature</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
  )
}

export default Header
